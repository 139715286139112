<template>
	<div>
		<v-card
			class="pt-6 mb20 shadow-blue-not-openned"
			:class="{
				pa5: $vuetify.breakpoint.smAndDown,
				pa15: $vuetify.breakpoint.mdAndUp,
			}"
		>
			<v-row :class="{ 'ortho-align-center': $vuetify.breakpoint.smAndDown }">
				<v-col md="4" cols="12">
					<h1>{{ whoAlreadyDidExercices }}%</h1>
				</v-col>
				<v-col md="8" cols="12" class="pt-0 pt-md-2 pb-0 pb-md-2">
					<h3>Des utilisateurs</h3>
					<p>Ont déjà fait leur session d'aujourd'hui</p>
				</v-col>
			</v-row>
			<v-col
				md="12"
				cols="12"
				class="text-center"
				v-if="
					formationProgress.formation.formation_finished !== true && formationProgress.formation.last_session_id != null
				"
			>
				<!-- Logic infinity exercise = true -->
				<template v-if="formationProgress.formation.infinity_exercice == true">
					<div v-if="passedTodaySession === true" class="ortho-button-exercice-container">
						<div style="width: 100%">
							<span>🎉 Bravo, prochaine session débloquée !</span>
							<div
								class="animate__animated animate__pulse animate__infinite ortho-start-session mt-4"
								@click="toCurrentSession()"
							>
								<strong>Session suivante</strong>
							</div>
						</div>
					</div>
					<div v-else-if="didTodaySession === true" class="ortho-button-exercice-container">
						<div style="width: 100%">
							<span>💪 Tu peux faire mieux !</span>
							<div
								class="animate__animated animate__pulse animate__infinite ortho-start-session mt-4"
								@click="toCurrentSession()"
							>
								<strong>Refaire ma session</strong>
							</div>
						</div>
					</div>
					<div v-else>
						<div
							class="animate__animated animate__pulse animate__infinite ortho-start-session"
							@click="toCurrentSession()"
						>
							<strong>Faire ma session du jour</strong>
						</div>
					</div>
				</template>
				<!-- End Logic infinity exercise = true -->

				<!-- Logic infinity exercise = false -->
				<template v-else>
					<div v-if="didThisWeekSession === false" class="ortho-button-exercice-container">
						<div
							class="animate__animated animate__pulse animate__infinite ortho-start-session"
							@click="toCurrentSession()"
						>
							<strong>Faire ma session de la semaine</strong>
						</div>
					</div>
					<div v-else-if="didThisWeekSession === true">
						<div v-if="passedThisWeekSession">
							<span>🎉 Bravo, prochaine session débloquée, à la semaine prochaine !</span>
						</div>
						<div style="width: 100%" v-else>
							<span>💪 Tu peux faire mieux !</span>
							<div
								class="animate__animated animate__pulse animate__infinite ortho-start-session mt-4"
								@click="toCurrentSession()"
							>
								<strong>Refaire ma session</strong>
							</div>
						</div>
					</div>
				</template>
				<!-- End Logic infinity exercise = false -->
			</v-col>
		</v-card>
	</div>
</template>

<script>
import { fakePercentUserDidExercise } from '@/utils/fake.js';
import { getStartOfNextWednesday, isPastGivenTime } from '@/utils/time.js';
import { isToday } from '@/utils/time.js';

export default {
	name: 'TodaySession',
	props: { formationProgress: Object },
	computed: {
		whoAlreadyDidExercices() {
			return fakePercentUserDidExercise();
		},
		didThisWeekSession() {
			if (
				this.formationProgress.sessionScoresHasTrackResponses == null ||
				this.formationProgress.sessionScoresHasTrackResponses.length === 0
			) {
				return false; //Not yet start any session, new user
			}

			const lastPassSessionScore = this.formationProgress.sessionScoresHasTrackResponses
				.filter((sessionScore) => sessionScore.passed_date)
				.sort((a, b) => new Date(b.passed_date) - new Date(a.passed_date))[0];

			if (lastPassSessionScore == null) return true;

			const startOfNextWedSinceLastPassedTime = getStartOfNextWednesday(lastPassSessionScore.passed_date);

			if (isPastGivenTime(startOfNextWedSinceLastPassedTime)) {
				if (lastPassSessionScore.session_id !== this.formationProgress.formation.last_session_id) return false;
			}
			return true;
		},
		passedThisWeekSession() {
			const lastPassSessionScore = this.formationProgress.sessionScoresHasTrackResponses
				.filter((sessionScore) => sessionScore.passed_date)
				.sort((a, b) => new Date(b.passed_date) - new Date(a.passed_date))[0];
			if (lastPassSessionScore == null) return false;

			const startOfNextWedSinceLastPassedTime = getStartOfNextWednesday(lastPassSessionScore.passed_date);
			if (isPastGivenTime(startOfNextWedSinceLastPassedTime)) return false;

			return true;
		},
		didTodaySession() {
			if (
				this.formationProgress.sessionScoresHasTrackResponses == null ||
				this.formationProgress.sessionScoresHasTrackResponses.length === 0
			) {
				return false; //Not yet start any session, new user
			}

			const lastCreatedSessionScore = [...this.formationProgress.sessionScoresHasTrackResponses].sort(
				(a, b) => new Date(b.created_at) - new Date(a.created_at)
			)[0];

			if (isToday(lastCreatedSessionScore.created_at)) return true;
			if (isToday(lastCreatedSessionScore.updated_at)) return true;

			return false;
		},
		passedTodaySession() {
			const lastPassSessionScore = this.formationProgress.sessionScoresHasTrackResponses
				.filter((sessionScore) => sessionScore.passed_date)
				.sort((a, b) => new Date(b.passed_date) - new Date(a.passed_date))[0];

			if (lastPassSessionScore == null) return false;

			return isToday(lastPassSessionScore.passed_date);
		},
	},
	methods: {
		toCurrentSession() {
			this.$router.push({
				name: 'SessionDetailPage',
				params: {
					formationId: this.formationProgress.formation._id,
					sessionId: this.formationProgress.formation.last_session_id,
				},
			});
		},
	},
};
</script>

<style></style>
