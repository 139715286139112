<template>
	<!-- <div
		v-intro="'<h2>Progresse et atteint le statut de maître</h2>Obtiens les différents statuts en remplissant la jauge avec les points gagnés.'"
		v-intro-tooltip-class="'custom-tooltip-introjs'"
	> -->
	<div>
		<h2>
			Progression
			<ScoreTooltip :parcours="parcours" />
		</h2>
		<v-col offset-md="0" md="11" cols="11">
			<div class="mx-auto">
				<ProgressionBarChartSession :parcours="parcours" />
			</div>
		</v-col>
	</div>
	<!-- </div> -->
</template>

<script>
import { mapGetters } from 'vuex';

import ProgressionBarChartSession from '@/components/charts/ProgressionBarChartSession';
import ScoreTooltip from './ScoreTooltip.vue';

export default {
	name: 'ProgressionBarForDashboardRedaction',
	components: { ProgressionBarChartSession, ScoreTooltip },
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	data() {
		return { parcours: null };
	},
	mounted() {
		const parcoursId = this.$route.params.parcoursId || this.$route.params.idParcours;
		this.getParcoursInFormationProgress(parcoursId);
	},
	watch: {
		formationProgress() {
			const parcoursId = this.$route.params.parcoursId || this.$route.params.idParcours;
			this.getParcoursInFormationProgress(parcoursId);
		},
		'$route.params.parcoursId'(newId, oldId) {
			if (newId === oldId) return;
			else this.getParcoursInFormationProgress(newId);
		},
		'$route.name'() {
			const parcoursId = this.$route.params.parcoursId || this.$route.params.idParcours;
			this.getParcoursInFormationProgress(parcoursId);
		},
	},
	methods: {
		getParcoursInFormationProgress(idParcours) {
			if (this.formationProgress == null || idParcours == null) return null;
			const currentParcoursItem = this.formationProgress.config.parcours_list.find(
				(item) => item.parcours_id._id === idParcours
			);
			if (currentParcoursItem == null) return;
			this.parcours = currentParcoursItem.parcours_id;
		},
	},
};
</script>

<style lang="scss" scoped></style>
