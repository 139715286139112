var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.$route.fullPath},[_vm._m(0),_vm._l((_vm.sortPositionAsc(_vm.formationProgress.config.parcours_list)),function(parcoursItem){return _c('router-link',{key:parcoursItem._id,class:{
			'ortho-disable': parcoursItem.status === 'locked',
			selected: _vm.isSelected(_vm.$route.params, parcoursItem.parcours_id._id),
			notSelect: !_vm.isSelected(_vm.$route.params, parcoursItem.parcours_id._id),
		},attrs:{"to":{
			name: 'Redaction Dashboard Parcours Detail',
			params: { parcoursId: parcoursItem.parcours_id._id },
		}}},[_c('v-row',{staticClass:"categoryContainer mt-3"},[_c('v-col',{staticClass:"d-flex align-center justify-space-between",attrs:{"cols":"12"}},[_c('span',{staticClass:"parcours-info"},[_vm._v(_vm._s(parcoursItem.parcours_id.name))]),(parcoursItem.status !== 'locked')?_c('div',[(parcoursItem.status === 'completed')?_c('span',[_c('v-icon',{staticClass:"iconsRules",staticStyle:{"width":"25px"},attrs:{"color":"#686bdf","alt":"category completed"}},[_vm._v("mdi-checkbox-multiple-marked-circle")])],1):(['open', 'onboarding', 'started', 'ongoing'].includes(parcoursItem.status))?_c('span',{staticClass:"parcours-info"},[_vm._v(" "+_vm._s(_vm.renderNumberOngoingCategoryOverTotal(parcoursItem.parcours_id.categories))+" ")]):_vm._e()]):_c('div',[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v("mdi-lock-outline")])],1)])],1)],1)}),_c('v-col',{staticStyle:{"padding-bottom":"0px"},attrs:{"cols":"12"}},[_c('OpenParcoursModal')],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Parcours")])])}]

export { render, staticRenderFns }