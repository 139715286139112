<template>
	<div :key="$route.fullPath">
		<div>
			<h2>Parcours</h2>
		</div>
		<router-link
			v-for="parcoursItem in sortPositionAsc(formationProgress.config.parcours_list)"
			:key="parcoursItem._id"
			:to="{
				name: 'Redaction Dashboard Parcours Detail',
				params: { parcoursId: parcoursItem.parcours_id._id },
			}"
			:class="{
				'ortho-disable': parcoursItem.status === 'locked',
				selected: isSelected($route.params, parcoursItem.parcours_id._id),
				notSelect: !isSelected($route.params, parcoursItem.parcours_id._id),
			}"
		>
			<v-row class="categoryContainer mt-3">
				<v-col cols="12" class="d-flex align-center justify-space-between">
					<span class="parcours-info">{{ parcoursItem.parcours_id.name }}</span>
					<div v-if="parcoursItem.status !== 'locked'">
						<span v-if="parcoursItem.status === 'completed'">
							<v-icon class="iconsRules" color="#686bdf" alt="category completed" style="width: 25px"
								>mdi-checkbox-multiple-marked-circle</v-icon
							>
						</span>
						<span
							class="parcours-info"
							v-else-if="['open', 'onboarding', 'started', 'ongoing'].includes(parcoursItem.status)"
						>
							{{ renderNumberOngoingCategoryOverTotal(parcoursItem.parcours_id.categories) }}
						</span>
					</div>
					<div v-else>
						<v-icon size="20px">mdi-lock-outline</v-icon>
					</div>
				</v-col>
			</v-row>
		</router-link>
		<v-col cols="12" style="padding-bottom: 0px">
			<OpenParcoursModal />
		</v-col>
	</div>
</template>

<script>
import OpenParcoursModal from './parcoursModal/OpenParcoursModal';
import { CategoryStatus } from '@/constants/category.js';

export default {
	name: 'ListParcoursSession',
	components: {
		OpenParcoursModal,
	},
	props: {
		formationProgress: Object,
	},
	methods: {
		renderNumberOngoingCategoryOverTotal(categories) {
			const totalCompleted = categories.filter((category) => category.status === CategoryStatus.COMPLETED).length;
			const totalOpenAndOnGoing = categories.filter(
				(category) => category.status === CategoryStatus.OPEN || category.status === CategoryStatus.ONGOING
			).length;
			if (totalCompleted === 0) return `${totalOpenAndOnGoing}/${categories.length}`;
			else if (totalCompleted === categories.length) return '';
			else return `${totalCompleted + totalOpenAndOnGoing}/${categories.length}`;
		},
		isSelected(routerParams, parcoursId) {
			const parcoursIdOnUrl = routerParams.parcoursId || routerParams.idParcours;
			if (parcoursIdOnUrl === parcoursId) return true;

			return false;
		},
		sortPositionAsc(parcoursList) {
			return [...parcoursList].sort((a, b) => a.position - b.position);
		},
	},
};
</script>

<style lang="scss" scoped>
.categoryContainer {
	-webkit-transition: background-color 0.5s ease-out;
	-moz-transition: background-color 0.5s ease-out;
	-o-transition: background-color 0.5s ease-out;
	transition: background-color 0.5s ease-out;
	&:hover {
		background-color: #f0f0fc;
		color: #686bdf;
		font-weight: bold;
	}
}
.selected {
	font-weight: bold;
	.categoryContainer {
		background-color: #f0f0fc;
	}
	.parcours-info {
		color: #686bdf;
	}
}
.notSelect {
	color: black;
}

.parcours-info {
	font-weight: bold;
	color: rgba(0, 0, 0, 0.54);
}
</style>
