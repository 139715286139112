<template>
	<div>
		<DotLoadingPage v-if="isRendering" />
		<RedactionDashboardLayout />
	</div>
</template>

<script>
import RedactionDashboardLayout from '@/views/layouts/RedactionDashboardLayout.vue';

import DotLoadingPage from '../../components/dot-loading-page/DotLoadingPage.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'RedactionDashboard',
	components: { DotLoadingPage, RedactionDashboardLayout },
	computed: {
		...mapGetters('appState', {
			isSwitchFormationStateLoading: 'isSwitchFormationStateLoading',
		}),
	},
	data() {
		return { isRendering: true };
	},
	mounted() {
		const duration = 5000;

		// The loading on this page is managed manually with a set duration. The status isSwitchFormationStateLoading allows us to check if another manual loading process is in progress.

		setTimeout(
			() => {
				this.isRendering = false;
			},
			this.isSwitchFormationStateLoading ? 0 : duration
		);
	},
};
</script>
