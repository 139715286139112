<template>
	<v-tooltip color="black" right>
		<template v-slot:activator="{ on }">
			<v-btn icon v-on="on">
				<v-icon size="20px" color="grey lighten-1">mdi-information-outline</v-icon>
			</v-btn>
		</template>
		<span>Points gagnés : {{ currentScore }}/{{ maxPoint }}</span>
	</v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

import { calculateTotalSessionParcoursPoint } from '@/utils/sessionScore.js';

export default {
	name: 'ScoreTooltip',
	props: { parcours: Object },
	computed: {
		...mapGetters('statuts', { statuts: 'statuts' }),
		currentScore() {
			if (this.parcours == null) return '';
			return calculateTotalSessionParcoursPoint(this.parcours);
		},
		maxPoint() {
			if (this.statuts == null || this.statuts.length === 0) return '';
			const sortedDescByPoints = [...this.statuts].sort((a, b) => b.palier_to_reach - a.palier_to_reach);
			return sortedDescByPoints[0].palier_to_reach;
		},
	},
};
</script>
