/**
 *
 * @param {number} sessionScore is in percent. Ex: 100 is 100%.
 * @returns {number}
 */
export const convertSessionScoreToPoint = (sessionScore) => {
	return sessionScore * 10;
};

export const calculateTotalSessionParcoursPoint = (parcours) => {
	if (parcours == null) return 0;
	let total = 0;

	parcours.categories.forEach((category) => {
		category.sessions.forEach((session) => {
			if (session.score == null) return;
			total = total + convertSessionScoreToPoint(session.score);
		});
	});

	return total;
};
